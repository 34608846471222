import React from 'react';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';

import Headers from "./component/Dropdown"

function App() {
  return (
    <MDBContainer fluid>
      <Headers />
      
    </MDBContainer>
  );
}

export default App;
